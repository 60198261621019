import { phoneValidationRule } from 'common/validation/phone-number-rule';
import { ValidationMessages } from 'common/validation/validation-messages';
import dayjs, { Dayjs } from 'dayjs';
import { RuleType } from 'rc-field-form/lib/interface';

export const sendFastAccessValidation = {
  guestName: [{ required: true, message: 'Guest name is required!' }],
  validFrom: [
    { required: true, message: 'Start date is required!' },
    ({ getFieldValue }: any) => ({
      validator: (_: any, value: Dayjs) => {
        if (value && value.isBefore(dayjs(), 'day')) {
          return Promise.reject(new Error(`Start date can't be in the past!`));
        }
        return Promise.resolve();
      },
    }),
  ],
  expires: [
    { required: true, message: 'End date is required!' },
    ({ getFieldValue }: any) => ({
      validator: (_: any, value: Dayjs) => {
        if (value && value.isBefore(dayjs(), 'day')) {
          return Promise.reject(new Error(`End date can't be in the past!`));
        }
        return Promise.resolve();
      },
    }),
    ({ getFieldValue }: any) => ({
      validator: (_: any, value: string) => {
        const startDate = getFieldValue('validFrom');
        if (value && value < startDate) {
          return Promise.reject(new Error('End date must be greater than start date'));
        }
        return Promise.resolve();
      },
    }),
  ],
  phone: [phoneValidationRule, { required: true, message: 'Please enter a phone number' }],
  email: [
    { required: true, message: ValidationMessages.Email.RequiredMessage },
    { message: ValidationMessages.Email.NotValidMessage, type: 'email' as RuleType },
  ],
};
